import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`How to sync UIDs and GIDs from Okta`}</h3>
    <p>{`Smallstep can automatically sync POSIX user and group IDs for your users from your identity provider.`}</p>
    <p>{`To set up UID and GID syncing, we're going to need our SCIM app to map those attributes from your Okta user profiles.`}</p>
    <p>{`This feature does not sync POSIX group membership mappings. Only UID and GID values are synced.`}</p>
    <h3>{`Step 1. Add UID and GID fields to your Okta user profile`}</h3>
    <p>{`Already have UID and GID fields for your users? Skip to Step 2.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Start at your Okta admin panel`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Go to `}<strong parentName="p">{`Directory → Profile Editor`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Under Filters, select `}<strong parentName="p">{`Okta`}</strong>{`. Choose `}<strong parentName="p">{`Profile`}</strong>{` or `}<strong parentName="p">{`User (default)`}</strong>{` here.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In the Profile Editor, choose `}<strong parentName="p">{`+ Add Attribute`}</strong>{` and add a uid attribute with data type `}<strong parentName="p">{`"number"`}</strong>{`.`}</p>
        <p parentName="li">{`You may want to provide an Attribute Range minimum here, especially if you want to protect a range of IDs for service accounts on your hosts.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/okta-gid-uidadd.png",
            "alt": null
          }}></img></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Save and Add Another`}</strong>{`, then create a GID attribute with data type `}<strong parentName="p">{`"number"`}</strong>{`.`}</p>
        <p parentName="li">{`You may want to provide an Attribute Range minimum here, especially if you want to protect a range of IDs for service accounts on your hosts.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/okta-gid-gidadd.png",
            "alt": null
          }}></img></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Save`}</strong></p>
      </li>
    </ul>
    <h3>{`Step 2. Add UID and GID fields to your Smallstep SCIM profile`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Go to `}<strong parentName="p">{`Directory → Profile Editor`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Search and select your `}<strong parentName="p">{`smallstep provisioning app`}</strong>{` profile. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Click the `}<strong parentName="p">{`+Add Attribute`}</strong>{` button and add a uid attribute.`}</p>
        <p parentName="li">{`Data type `}<strong parentName="p">{`number`}</strong></p>
        <p parentName="li">{`Set the Display name, Variable name, and External name to `}<inlineCode parentName="p">{`uid`}</inlineCode></p>
        <p parentName="li">{`External namespace  `}<inlineCode parentName="p">{`urn:scim:smallstep:ssh:schema`}</inlineCode></p>
        <p parentName="li">{`The attribute should be marked `}<strong parentName="p">{`required`}</strong>{`, because any Okta user with an empty uid or gid value won't sync to Smallstep.`}</p>
        <p parentName="li">{`Scope ☑ `}<strong parentName="p">{`User personal`}</strong></p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/okta-gid-uidsync.png",
            "alt": null
          }}></img></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Save and Add Another,`}</strong>{` and add a gid attribute.`}</p>
        <p parentName="li">{`Data type `}<strong parentName="p">{`number`}</strong></p>
        <p parentName="li">{`Set the Display name, Variable name, and External name to `}<inlineCode parentName="p">{`gid`}</inlineCode></p>
        <p parentName="li">{`External namespace  `}<inlineCode parentName="p">{`urn:scim:smallstep:ssh:schema`}</inlineCode></p>
        <p parentName="li">{`The attribute should be marked `}<strong parentName="p">{`required`}</strong>{` because any Okta user with an empty uid or gid value won't sync to Smallstep.`}</p>
        <p parentName="li">{`Scope ☑ `}<strong parentName="p">{`User personal`}</strong></p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/okta-gid-uidsync.png",
            "alt": null
          }}></img></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Save`}</strong></p>
      </li>
    </ul>
    <h3>{`Step 3. Add mappings from Okta to your Smallstep provisioning app`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Go to `}<strong parentName="p">{`Applications → Applications`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Choose your `}<strong parentName="p">{`smallstep provisioning`}</strong>{` application`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Go to the `}<strong parentName="p">{`Provisioning`}</strong>{` tab`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Under `}<strong parentName="p">{`Attribute Mappings`}</strong>{`, you should see that uid and gid are not mapped.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Edit the mapping for `}<inlineCode parentName="p">{`uid`}</inlineCode>{`:`}</p>
        <p parentName="li">{`Select a type equal to `}<strong parentName="p">{`Map from Okta Profile`}</strong></p>
        <p parentName="li">{`Choose the `}<strong parentName="p">{`uid | number`}</strong>{`  field.`}</p>
        <p parentName="li">{`Apply on `}<strong parentName="p">{`Create and update`}</strong></p>
        <p parentName="li"><strong parentName="p">{`Save`}</strong></p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/okta-gid-createupdate.png",
            "alt": null
          }}></img></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Repeat`}</strong>{` for `}<inlineCode parentName="p">{`gid`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When you're finished, the mappings should look like this:`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/okta-gid-finalmap.png",
            "alt": null
          }}></img></p>
      </li>
    </ul>
    <h3>{`Step 4. Verify syncing with smallstep before going into production`}</h3>
    <ul>
      <li parentName="ul">{`These changes should trigger a sync of the UID and GID values to the smallstep dashboard. `}</li>
      <li parentName="ul">{`If the values do not show up, try removing and re-adding the group assignments:`}
        <ul parentName="li">
          <li parentName="ul">{`Open the `}<strong parentName="li">{`smallstep provisioning app integration`}</strong>{` application within OKTA.`}</li>
          <li parentName="ul">{`Go to the `}<strong parentName="li">{`Assignments`}</strong>{` tab, select `}<strong parentName="li">{`groups`}</strong>{`, and `}<strong parentName="li">{`remove all the groups`}</strong>{` (remember these group names). `}</li>
          <li parentName="ul">{`Wait until the users are removed from the smallstep dashboard (a few seconds).`}</li>
          <li parentName="ul">{`Then re-add the groups using the `}<strong parentName="li">{`assign`}</strong>{` button. `}</li>
          <li parentName="ul">{`This will trigger a push, and you will see the new uid and gid values in the smallstep dashboard.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Send an email to `}<a parentName="p" {...{
        "href": "mailto:support@smallstep.com"
      }}>{`support@smallstep.com.`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      